
import AppMixin from '~/mixins/App'
import envMixin from "@/mixins/envMixin";

export default {
  name: 'MenuIndex',
  mixins: [AppMixin, envMixin],
  data() {
    return {
      showSidebar: false,
      isVi: false
    }
  },

  watch: {
    $route: {
      handler(val) {
        if (val.path === '/vi') {
          this.isVi = true
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    onClickOutSide() {
      this.showSidebar = false
    },

    onClickMenu() {
      this.showSidebar = false
    },

    goTo(url) {
      if (!url) {
        return
      }

      window.open(url, '_blank')
      this.showSidebar = false
    }
  }
}
